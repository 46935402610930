<template>
  <div class="other-header-bottom">
    <div class="container df jc-fs ai-c">
      <SupervisorButtons
        v-if="isSupervisorPage"
        class="supervisor-buttons"
        @samples="routeTo('SupervisorSamples')"
        @archive="routeTo('SupervisorArchive')"
      />
      <iq-button
        v-if="!isExporter && !isSupervisorsChildPage && !isMobile"
        icon="IconRefresh"
        icon-height="14px"
        icon-width="14px"
        class="m-l-10"
        @onClick="handleClickRefresh"
      >
        Обновить страницу
      </iq-button>
      <div v-if="isSupervisorsChildPage">
        <el-button
          type="info"
          plain
          icon="el-icon-arrow-left"
          class="header-back-button"
          @click="goBack"
        >
          Назад
        </el-button>
      </div>

      <div
        v-if="isSuperAdmin || isSupport || isExporter || isParkman"
        class="other-header-bottom__select-wrap"
      >
        <el-select
          v-model="currentTerminal"
          class="custom-component other-header-bottom__select"
          placeholder="Выберите город"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div v-if="isMobile" class="bottomMobile">
      <add-supervisor-quota-button v-if="showSupervisorAddButton" />
    </div>
    <mobile-menu v-if="isSuperAdmin" />
  </div>
</template>

<script>
import {
  AUTH_LOGOUT,
  GET_IS_MOBILE,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
  GET_UPDATE_ACTION,
  SET_DRAWER,
  SET_TERMINAL,
} from '@/store/actions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { replaceCurrentTerminal } from '@/core'
import AddSupervisorQuotaButton from '@/views/supervisor/components/AddSupervisorQuotaButton.vue'
import IqButton from '@/UI/buttons/IqButton'
import MobileMenu from '@/components/drawer/mobile-menu/MobileMenu'
import SupervisorButtons from '@/views/supervisor/components/SupervisorButtons.vue'

export default {
  name: 'OtherHeaderBottom',
  components: {
    AddSupervisorQuotaButton,
    IqButton,
    MobileMenu,
    SupervisorButtons,
  },
  props: {
    isShowProfilePage: {
      type: Boolean,
      default: false,
    },
    mainPageParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      refresh: GET_UPDATE_ACTION,
      options: GET_TERMINAL_LIST,
      currentTerminalId: GET_TERMINAL_CURRENT_ID,
      isMobile: GET_IS_MOBILE,
    }),
    currentTerminal: {
      get() {
        return this.currentTerminalId
      },
      set(newVal) {
        let newTerminal = this.options.find(t => t.id === newVal)

        replaceCurrentTerminal(newTerminal)
        this.setTerminal(newTerminal)
      },
    },
    isSupervisorPage() {
      return (
        this.$route.name === 'Supervisor' ||
        this.$route.name === 'SupervisorTable'
      )
    },
    isSupervisorsChildPage() {
      return (
        this.$route.name === 'SupervisorArchive' ||
        this.$route.name === 'SupervisorSamples'
      )
    },
    showSupervisorAddButton() {
      return (
        this.$route.name === 'SupervisorTable' ||
        this.$route.name === 'SupervisorSamples'
      )
    },
  },
  watch: {
    currentTerminalId() {
      if (this.refresh) {
        this.refresh()
      }
    },
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    ...mapMutations({ setDrawer: SET_DRAWER, setTerminal: SET_TERMINAL }),
    routeTo(name) {
      this.$router.push({ name })
    },
    goBack() {
      let name = localStorage.getItem('supervisor-page-format').slice(5)

      this.routeTo(name)
    },
    handleClickRefresh() {
      if (typeof this.refresh === 'function') {
        this.refresh()
      }
    },
  },
}
</script>

<style lang="sass">
.other-header-bottom
  padding: $space-16
  background: $color-white
  min-height: 60px
  box-shadow: 0 4px 44px 4px rgba(0, 0, 0, 0.13)
  +to(768px)
    background: none
  .container
    +to(768px)
      justify-content: space-between
  &__icon +   &__icon
      margin-left: 20px
  &__icon,
  &__icon line
    stroke: $color-black
    cursor: pointer
    transition: stroke .3s ease
    font-size: 30px
    font-weight: bold
    path
      stroke: $color-black
      transition: stroke .3s ease
    &:hover,
    &:hover line
      stroke: $color-blue-primary
      path
        stroke: $color-blue-primary

  &__select-wrap
    margin-left: auto

  &__select
    width: 300px
    margin: 0 !important

    .el-input__icon
      line-height: 30px

  .supervisor-buttons
    @media(max-width: 1199px)
      padding-left: 0

.bottomMobile
  position: fixed
  bottom: 0
  left: 0
  right: 0
  padding: 16px
  background-color: #fff
  display: flex
  align-items: center
  justify-content: center
  z-index: 100
  :deep(.search)
    justify-content: center
</style>
