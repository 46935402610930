var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "other-header-bottom" },
    [
      _c(
        "div",
        { staticClass: "container df jc-fs ai-c" },
        [
          _vm.isSupervisorPage
            ? _c("SupervisorButtons", {
                staticClass: "supervisor-buttons",
                on: {
                  samples: function ($event) {
                    return _vm.routeTo("SupervisorSamples")
                  },
                  archive: function ($event) {
                    return _vm.routeTo("SupervisorArchive")
                  },
                },
              })
            : _vm._e(),
          !_vm.isExporter && !_vm.isSupervisorsChildPage && !_vm.isMobile
            ? _c(
                "iq-button",
                {
                  staticClass: "m-l-10",
                  attrs: {
                    icon: "IconRefresh",
                    "icon-height": "14px",
                    "icon-width": "14px",
                  },
                  on: { onClick: _vm.handleClickRefresh },
                },
                [_vm._v(" Обновить страницу ")]
              )
            : _vm._e(),
          _vm.isSupervisorsChildPage
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "header-back-button",
                      attrs: {
                        type: "info",
                        plain: "",
                        icon: "el-icon-arrow-left",
                      },
                      on: { click: _vm.goBack },
                    },
                    [_vm._v(" Назад ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isSuperAdmin || _vm.isSupport || _vm.isExporter || _vm.isParkman
            ? _c(
                "div",
                { staticClass: "other-header-bottom__select-wrap" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass:
                        "custom-component other-header-bottom__select",
                      attrs: { placeholder: "Выберите город" },
                      model: {
                        value: _vm.currentTerminal,
                        callback: function ($$v) {
                          _vm.currentTerminal = $$v
                        },
                        expression: "currentTerminal",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isMobile
        ? _c(
            "div",
            { staticClass: "bottomMobile" },
            [
              _vm.showSupervisorAddButton
                ? _c("add-supervisor-quota-button")
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isSuperAdmin ? _c("mobile-menu") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }